import React, { useEffect } from "react"
import { Global, css } from "@emotion/core"
import backgroundvideo from "../../static/media/3811a9a6-07b7-4c34-a0dd-b836782e1b50.mp4"
import "typeface-inter"
import SEO from "../components/SEO/seo"
import mq from "../components/defaults/styles/mediaquerys";



// const EXPO7 = () => {
//   return(
//     <div css={css`
//       position: absolute;
//       top: 25px;
//       right: 25px;
//       display: none;
//       ${mq[2]} {
//         display: block;
//       }
//       a {
//         color: white;
//         font-size: .8em;
//         letter-spacing: 0.06em;
//         padding: .5rem .9rem;
//         text-decoration: none;
//         border: 1px solid white;
//         border-radius: 5px;
//       }  
//     `}>
//       <a href="https://expo7.pnptc.events" rel="noreferrer" target="_blank">Last Years EXPO Day 7</a>
//     </div>
//   )
// }

const Welcome = () => {
    // const size = useWindowSize()
    // const [windowHeight, setWindowHeight] = useState(0)
    
    // useLayoutEffect(() => {
    //   setWindowHeight(window.innerHeight)
    // }, [windowHeight]) 
    
    useEffect(() => {
      ;(function () {
        var 
          //qs,
          js,
          q,
          //s,
          d = document,
          gi = d.getElementById,
          ce = d.createElement,
          gt = d.getElementsByTagName,
          id = "typef_orm_share",
          b = "https://embed.typeform.com/"
        if (!gi.call(d, id)) {
          js = ce.call(d, "script")
          js.id = id
          js.src = b + "embed.js"
          q = gt.call(d, "script")[0]
          q.parentNode.insertBefore(js, q)
        }
      })()
    }, []) 


    const seo = {
        frontmatter: {
            title: 'Welcome'
        }
    }

    return (
      <>
        <Global
          styles={css`
            body,
            html,
            #___gatsby,
            #gatsby-focus-wrapper {
              height: 100%;
            }
          `}
        />
        <SEO {...seo} />
        <section
          css={css`
            background: black;
            min-height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem 0;
          `}
        >
          <div
            css={css`
              color: white;
              text-align: center;
            `}
          >
            <video
              autoPlay
              loop
              playsInline
              muted
              css={css`
                max-width: 80%;
                width: 30rem;
                margin: 0 auto;
                display: block;
              `}
            >
              <source src={backgroundvideo} type="video/mp4" />
            </video>
            <div
              css={css`
                text-align: center;
                padding: 5rem 1.5rem 2rem;
                span {
                  background: white;
                  color: black;
                  display: inline-block;
                  padding: 3px;
                  line-height: 1em;
                  font-size: 0.75em;
                  /* letter-spacing: .1em; */
                  border-radius: 3px;
                  font-weight: 700;
                  margin: 0 0.3em;
                }
              `}
            >
              <div
                css={css`
                  margin-bottom: 2rem;
                  p {
                    margin: 0;
                    font-size: 0.8em;
                    letter-spacing: 0.06em;
                  }
                `}
              >
                <p>This will be unlike anything of its kind.</p>
                <p>We are going limitless.</p>
                <p>We will expose the power of open innovation.</p>
                <p>
                  This is the work of collaboration & perseverance.
                </p>
              </div>
              <p
                css={css`
                  font-weight: 600;
                `}
              >
                The sum of innovation is infinite.
              </p>
              <p>
                September <span>23</span> and <span>24</span>
              </p>
            </div>
            <div css={css`
              padding: 0 1rem;
            `}>
              <a
              className="typeform-share button"
              href="https://form.typeform.com/to/USZTzCEv"
              target="_blank"
              rel="noreferrer"
              css={css`
              color: white;
              text-decoration: none;
              border: 1px solid white;
              padding: 0.6rem 1.2rem 0.7rem;
              border-radius: 0.3rem;
              display: inline-block;
              `}
              >
              Save your spot
              </a>
              <a href="https://expo7.pnptc.events" css={css`
                padding: 1.3rem 1.2rem 0.7rem;
                color: white;
                text-decoration: none;
                font-size: .8em;
                display: block;
                ${mq[0]} {
                  padding: 0.6rem 0 0.7rem 1.2rem;
                  display: inline-block;
                }
              `}>or REAЯVIEW Expo 7</a>
            </div>
          </div>
        </section>
      </>
    )
}

export default Welcome;